// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";
// normalize CSS across browsers
import "./src/normalize.css";
// custom CSS styles
import "./src/style.css";

// Highlighting for code blocks
import "prismjs/themes/prism.css";
// import "prismjs/themes/prism-okadia.css"
import React from "react";
// https://apuyou.io/blog/mixpanel-gatsby
import mixpanel from "mixpanel-browser";

import { MixpanelContext } from "./src/mixpanel_tracking";

export const wrapRootElement = ({ element }) => {
  mixpanel.init("b30ed8e4c273d5599202443cd6086d39");

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {element}
    </MixpanelContext.Provider>
  );
};
